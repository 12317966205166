import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '../userStoreModule'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    // { key: 'email', sortable: true },
    { key: 'role', sortable: true },
    { key: 'timeInRole', sortable: true },
    { key: 'level', label: 'Level', sortable: true },
    { key: 'location', sortable: true },
    { key: 'advisor', label: 'Advisor', sortable: true },
    { key: 'isActive', label: 'Active', sortable: true },

    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const limit = ref(10)
  const totalUsers = ref(0)
  const page = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('updatedAt')
  const sortOrder = ref('desc')
  const roleFilter = ref(null)
  const levelFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: limit.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (page.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([page, limit, searchQuery, roleFilter, levelFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        limit: limit.value,
        page: page.value,
        sortBy: sortBy.value,
        sortOrder: sortOrder.value,
        role: roleFilter.value,
        level: levelFilter.value,
        isActive: statusFilter.value === 'active' ? true : statusFilter.value === 'inactive' ? false : null,
      })
      .then(response => {
        const { users, total } = response.data
        // Removing the PRS Admin user 
        const filteredUsers = users.filter((u) => { return (u.level.name.toLowerCase() != 'prs admin') })
        callback(filteredUsers)
        totalUsers.value = total - 1
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'primary'
    if (role === 'admin') return 'info'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    limit,
    page,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    sortOrder,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    levelFilter,
    statusFilter,
  }
}
