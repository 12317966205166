<template>

  <div>

    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :level-options="levelOptions" :advisor-options="advisorOptions" :location-options="locationOptions"
      @refetch-data="refetchData" />

    <user-list-edit :is-edit-user-sidebar-active.sync="isEditUserSidebarActive" :role-options="roleOptions"
      :level-options="levelOptions" :advisor-options="advisorOptions" :location-options="locationOptions" :item="item"
      @refetch-data="refetchData" :isCycleActive="isCycleActive" />

    <!-- Filters -->
    <users-list-filters :role-filter.sync="roleFilter" :level-filter.sync="levelFilter"
      :status-filter.sync="statusFilter" :role-options="roleOptions" :level-options="levelOptions"
      :status-options="statusOptions" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="limit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="fetchUsers" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="sortOrderSync">
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Loading..."></b-spinner>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`" />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap lead">
              {{ data.item.fullName }}
            </b-link>
            <span class="text-secondary h5">{{ data.item.email }}</span>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap h5">
            <b-badge :variant="`light-${resolveUserRoleVariant(data.item.role)}`">
              <feather-icon :icon="resolveUserRoleIcon(data.item.role)" class="mr-25" />
              <span class="align-text-top text-capitalize"> {{ data.item.role }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(timeInRole)="data">
          <span class="">{{ timeInRole(data.item.roleStartDate) }}</span>
        </template>

        <template #cell(level)="data">
          <span class="">{{ data.item.level.name }}</span>
        </template>

        <template #cell(location)="data">
          <!-- <b-badge :variant="`light-${resolveUserRoleVariant(data.item.role)}`"> -->
          <span v-if="data.item.location">
            <feather-icon icon="MapPinIcon" class="mr-25" />
            <span class="align-text-top text-capitalize"> {{ data.item.location }}</span>
          </span>
          <span v-else><b-badge variant="light-secondary">N/A</b-badge> </span>
        </template>

        <template #cell(advisor)="data">
          <b-media v-if="data.item.advisor" vertical-align="center">
            <b-link class="font-weight-bold d-block text-nowrap ">
              {{ data.item.advisor.fullName }}
            </b-link>
            <span class="text-secondary ">{{ data.item.advisor.email }}</span>
          </b-media>
          <span v-else><b-badge variant="light-secondary">N/A</b-badge> </span>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(isActive)="data">
          <b-badge :variant="data.item.isActive ? 'success' : 'danger'" />
        </template> -->
        <template #cell(isActive)="data">
          <span
            :class="data.item.isActive ? 'status-dot status-dot--active' : 'status-dot status-dot--inactive'"></span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon icon="EditIcon" v-b-tooltip.hover title="Edit User" class="edit-button ml-1"
            @click="editItem(data.item)" />
          <feather-icon icon="LockIcon" v-b-tooltip.hover title="Reset Password" class="details-button ml-50"
            @click="forgotPassword(data.item)" />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="page" :total-rows="totalUsers" :per-page="limit" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { ref, onUnmounted, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import UserListEdit from './UserListEdit.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { timeInRole } from '../../utils'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    UserListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const advisorOptions = ref([])
    const locationOptions = ref([])
    const levelOptions = ref([])
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'User', value: 'user' },
    ]
    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const item = ref(null)

    const isAddNewUserSidebarActive = ref(false)
    const isEditUserSidebarActive = ref(false)
    const isCycleActive = ref(true)

    const { proxy } = getCurrentInstance()

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // Fetch advisorOptions
    onMounted(() => {
      proxy.$http.get('/users')
        .then(response => {
          const users = response.data.users.filter((u) => { return (u.level.name.toLowerCase() != 'prs admin') })
          advisorOptions.value = users.map(user => ({ label: user.fullName, value: user._id }))
        })
        .catch(error => console.error('Error fetching advisor options:', error))
    })

    // Fetch locationOptions
    onMounted(() => {
      proxy.$http.get('/locations')
        .then(response => {
          const locations = response.data.locations
          locationOptions.value = locations.map(location => (location.name))
        })
        .catch(error => console.error('Error fetching location options:', error))
    })

    // Fetch locationOptions
    onMounted(() => {
      proxy.$http.get('/levels')
        .then(response => {
          const levels = response.data.levels
          levelOptions.value = levels.map(level => ({ label: level.name, value: level._id, dept: level.department }))
        })
        .catch(error => console.error('Error fetching level options:', error))
    })

    onMounted(() => {
      proxy.$http.get('/cycles/isActive')
        .then(response => {
          isCycleActive.value = response.data.isActive
        }).catch(error => console.error('Error fetching if cycle is Active:', error))
    })

    const {
      fetchUsers,
      tableColumns,
      limit,
      page,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      levelFilter,
      statusFilter,
    } = useUsersList()

    const sortOrderSync = computed({
      get: () => sortOrder.value === 'desc',
      set: (newVal) => {
        sortOrder.value = newVal ? 'desc' : 'asc'
      }
    })



    return {


      // Sidebar
      isAddNewUserSidebarActive,
      isEditUserSidebarActive,

      isCycleActive,

      fetchUsers,
      tableColumns,
      limit,
      page,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      sortOrderSync,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      levelOptions,
      statusOptions,
      advisorOptions,
      locationOptions,

      // Extra Filters
      roleFilter,
      levelFilter,
      statusFilter,
      item
    }
  },
  methods: {
    editItem(item) {
      this.item = item
      this.isEditUserSidebarActive = true
    },
    forgotPassword(item) {
      this.$bvModal
        .msgBoxConfirm(`This will send a One-Time Password to ${item.email}, which should be changed after login.`, {
          title: `Would you like to reset ${item.fullName}'s password?`,
          size: 'lg',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$http.put(`/users/${item._id}/forgotPassword`)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Password Reset Successfully!',
                    icon: 'CheckIcon',
                    variant: 'success',
                  }
                })
              })
              .catch((err) => {
                const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    text: errorMessage,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })

          }
        })
    },
    timeInRole,
  }
}
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot--active {
  background-color: rgb(0, 165, 0);
}

.status-dot--inactive {
  background-color: rgba(255, 0, 0, 0.76);
}

.edit-button {
  color: $primary;
  cursor: pointer;
}

.details-button {
  color: $primary;
  cursor: pointer;
}
</style>
