<template>
  <b-sidebar id="edit-user-sidebar" :visible="isEditUserSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit User
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- User fullname -->
          <validation-provider #default="validationContext" name="Full Name" rules="required|">
            <b-form-group label="Full Name*" label-for="fullName">
              <b-form-input id="fullName" v-model="userData.fullName" :state="getValidationState(validationContext)"
                trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Email -->
          <validation-provider #default="validationContext" name="Email" rules="required|email">
            <b-form-group label="Email*" label-for="email">
              <b-form-input disabled id="email" v-model="userData.email" :state="getValidationState(validationContext)"
                trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Level -->
          <validation-provider #default="validationContext" name="Level" rules="required">
            <b-form-group label="Level*" label-for="level">
              <v-select :disabled="isCycleActive" v-model="userData.level" :options="levelOptions" :clearable="false"
                input-id="level" :reduce="level => level.value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Role -->
          <validation-provider #default="validationContext" name="Role" rules="required">
            <b-form-group label="Role*" label-for="role">
              <v-select v-model="userData.role" :options="roleOptions" :clearable="false" input-id="role"
                :reduce="role => role.value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Role Start Date-->
          <validation-provider #default="validationContext" name="roleStartDate" rules="required">
            <b-form-group label="Role Start Date*" label-for="roleStartDate">
              <b-form-datepicker id="roleStartDate" :state="!!userData.roleStartDate" v-model="userData.roleStartDate" class="mb-2" value-as-date />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Advisor -->
          <validation-provider #default="validationContext" name="Advisor">
            <b-form-group label="Advisor" label-for="advisor">
              <v-select :disabled="isAdvisorDisabled" v-model="userData.advisor" :options="advisorOptions"
                input-id="advisor" :reduce="advisor => advisor.value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Location -->
          <validation-provider #default="validationContext" name="Location">
            <b-form-group label="Location" label-for="location">
              <v-select v-model="userData.location" :options="locationOptions" input-id="location" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Comments -->
          <validation-provider #default="validationContext" name="Comments">
            <b-form-group label="Comments" label-for="comments">
              <b-form-textarea id="comments" v-model="userData.comments" :state="getValidationState(validationContext)"
                trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <small v-if="isCycleActive" class="text-warning"> *Cannot edit user's level when a cycle is active. </small>
          <small v-if="isInvalidRoleLevel" class="text-danger"><br /> *External users cannot be Admins. </small>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="isInvalidRoleLevel">
              Save
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch, getCurrentInstance, computed } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditUserSidebarActive',
    event: 'update:is-edit-user-sidebar-active',
  },
  props: {
    isCycleActive: {
      type: Boolean,
      required: true,
    },
    isEditUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    levelOptions: {
      type: Array,
      required: true,
    },
    locationOptions: {
      type: Array,
      required: true,
    },
    advisorOptions: {
      type: Array,
      required: true,
    },
    item: {
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    // Fetch advisors, levels, and locations on mount
    const blankUserData = ref({
      fullName: '',
      email: '',
      level: '',
      role: '',
      roleStartDate: new Date(),
      advisor: null,
      location: '',
      comments: '',
    })

    const isAdvisorDisabled = ref(false)

    const userData = ref({ ...blankUserData.value })

    const resetUserData = () => {
      userData.value = { ...blankUserData.value }
    }

    // Watch for changes to the sidebar active state
    watch(() => props.isEditUserSidebarActive, (newVal) => {
      if (newVal && props.item) {
        // Populate the form fields when the sidebar is opened
        userData.value.fullName = props.item.fullName
        userData.value.email = props.item.email
        userData.value.level = props.item.level._id
        userData.value.role = props.item.role
        userData.value.roleStartDate = props.item.roleStartDate
        userData.value.advisor = props.item.advisor ? props.item.advisor._id : null
        userData.value.location = props.item.location
        userData.value.comments = props.item.comments ? props.item.comments : ''

      }
    }, { immediate: true })

    const { proxy } = getCurrentInstance()

    const onSubmit = () => {
      userData.value.roleStartDate = new Date(new Date(userData.value.roleStartDate).setHours(13, 0, 0))
      store.dispatch('app-user/editUser', { id: props.item._id, userData: userData.value })
        .then(() => {
          emit('update:is-edit-user-sidebar-active', false)
          emit('refetch-data')
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'User Saved Successfully!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch((err) => {
          const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              text: errorMessage,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    // Computed property to check invalid role and level
    const isInvalidRoleLevel = computed(() => {
      // Find the level object that matches the userData level value
      const level = proxy.levelOptions.find(l => l.value === userData.value.level);

      const levelDept = level ? level.dept : null;

      // Check if the role is 'admin' and the level dept is 'External'
      return userData.value.role === 'admin' && levelDept === "external";
    })

    // Watch for changes to the sidebar active state
    watch(() => userData.value.level, (newVal) => {
      if (newVal) {
        const level = proxy.levelOptions.find(l => l.value === newVal);
        const levelDept = level ? level.dept : null;
        if (levelDept === "external") {
          userData.value.advisor = null;
          isAdvisorDisabled.value = true;
        } else {
          isAdvisorDisabled.value = false;
        }
      }
    }, { immediate: true })

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      isInvalidRoleLevel,
      isAdvisorDisabled
    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
